import styles from "./ResetPassForm.module.scss"
import { useMachine } from "@xstate/react";
import { useRef, useState } from "react";
import {Icon} from "../SVGsprite/svgsprite";
import {Loader} from "../Loader/Loader";
import clsx from "clsx";
import { validateEmail } from "../../helpers/validateAuthFormFields";
import { useDialogState } from "reakit";
import { RegisterInfoModal } from "../RegisterInfoModal/RegisterInfoModal";
import { DialogDisclosure } from "reakit";
import ReCAPTCHA from "react-google-recaptcha";
import { restorePasswordMachine } from "../../xstate/restorePassword.machine";

export function ResetPassForm() {

    const captchaRef = useRef(null);

    let [stateRestore, sendRestore] = useMachine(restorePasswordMachine);

    let dialog = useDialogState({animated: true});

    let [errorLogin, setErrorLogin] = useState("");
    let [loginState, setLoginState] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        const token = captchaRef.current.getValue();
        captchaRef.current.reset();
        let FD = new FormData(event.target),
            login = FD.get("login");
        
        const loginError = validateEmail(login);

        if (!loginError) {
            sendRestore({
                type: "sendData",
                data: {
                    "email": login,
                    "recaptcha": token,
                }
            })
        }

        if (loginError) {
            setErrorLogin(loginError);
        }
    }

    return (<>
            {
                stateRestore.matches("loading") &&
                <section className={styles.loader}><Loader/></section>
            }

            <section className="formWrapper">
                <div className={clsx("wrapperSecondary", styles.wrapper)}>
                    <h1 className={"textCenter"}>Восстановление пароля</h1>
                    {
                        stateRestore.context.errorMsg.length > 0 &&
                        stateRestore.context.errorMsg.map(
                            (item, index) => (
                                <div key={index} className={"errorMessage"}>
                                    {item}
                                </div>)
                        )
                    }
                    {
                        stateRestore.context.successMsg.length > 0 &&
                        stateRestore.context.successMsg.map(
                            (item, index) => (
                                <div key={index} className={"successMessage"}>
                                    {item}
                                </div>)
                        )
                    }
                    <form 
                        onSubmit={handleSubmit}
                        noValidate
                    >
                        <div className="formRow">
                            <label htmlFor="login">E-mail</label>
                            <input
                                className={clsx(
                                    {"error": Boolean(errorLogin)}
                                )}
                                id={"login"}
                                name={"login"}
                                autoComplete={"email"}
                                type="email"
                                onChange={(event) => {
                                    setLoginState(event.target.value)
                                    setErrorLogin("")
                                }}
                                value={loginState}
                            />

                            {Boolean(errorLogin) && <div className="errorMessage">{errorLogin}</div>}
                        </div>
                        <div className={styles.captcha}>
                            <ReCAPTCHA 
                                sitekey={import.meta.env.VITE_RECAPTCHA_KEY}
                                ref={captchaRef}
                            />
                        </div>
                        <div className="formRow">
                            <button
                                type="submit"
                                className={"btn btnPrimary"}
                            >
                                Восстановить
                            </button>
                        </div>
                        <div className={styles.linksRow}>
                            <DialogDisclosure
                                {...dialog}
                                className="extraLink"
                            >
                                Как зарегистрироваться? 
                                <span className={"visuallyHidden"}>Как зарегистрироваться?</span>
                            </DialogDisclosure>
                        </div>
                    </form>
                </div>
                <RegisterInfoModal
                    dialog={dialog}
                />
            </section>
        </>
    )
}