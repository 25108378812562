import {assign, createMachine} from "xstate";
import { api } from "../api/api";
import apiMethods from "../api/apiMethods";

export const restorePasswordMachine = createMachine({
    id: 'restorePassword',
    context: {
        errorMsg: [],
        successMsg: [],
    },
    initial: "init",
    states: {
        "init": {
            on: {
                "sendData": {
                    target: "loading"
                },
            }
        },
        "loading": {
            invoke: {
                id: "sendEmail",
                src: "sendEmail",
            },
            on: {
                "success": {
                    target: "success",
                    actions: ["assignSuccessContext", "removeErrorFromContext"]
                },
                "error": {
                    target: "failure",
                    actions: ["assignErrorToContext", "removeSuccessFromContext"],
                }
            }
        },
        "failure": {},
        "success": {},
    }
},
{
    actions: {
        "assignSuccessContext": assign((ctx, message) => ({
            "successMsg": [message.data]})),
        "assignErrorToContext": assign((ctx, message) => ({errorMsg: [message.data.message]})),
        "removeErrorFromContext": assign({
            "errorMsg": []
        }),
        "removeSuccessFromContext": assign({
            "successMsg": []
        }),
    },
    services: {
        "sendEmail": (ctx, message) => async function (send) {
                try {
                    const response = await apiMethods.authentication.restore(message.data);
                        send({
                            type: "success",
                            data: response.data.message
                        });
                } catch ({response}) {
                    send({
                        type: "error",
                        data: response.data,
                    })
                }
            },
    }
});
