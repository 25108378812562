import clsx from "clsx";
import { Link, NavLink } from "react-router-dom";
import { Dialog, DialogDisclosure, useDialogState } from "reakit";
import { useActor } from "@xstate/react";
import styles from "./Header.module.scss";
import { Icon } from "../SVGsprite/svgsprite";
import { AuthState } from "../../xstate/auth.machine";
import { AccountMenu } from "../AccountMenu/AccountMenu";

export function Header(props) {
  const [stateAuth] = useActor(AuthState);

  return (
    <header
      role="banner"
      className={clsx(styles.header, {
        [styles.dark]: props.dark,
        [styles.account]: props.account,
      })}
    >
      <a href="#main" className="skipLink">
        Перейти к основному контенту
      </a>
      <div className={clsx("wrapper", styles.headerWrapper)}>
        <Logo dark={props.dark} />

        {props.account && <UserMenuMobile />}

        <MobileMenu account={props.account} dark={props.dark} />
        <nav className={clsx(styles.menu, styles.menuDesktop)}>
          <div className={styles.links}>
            {stateAuth.matches("auth") &&
            !["distributor", "owner"].includes(stateAuth.context.type) &&
            stateAuth.context.id ? (
              <NavLink to="/">Главная</NavLink>
            ) : (
              <></>
            )}

            {stateAuth.matches("auth") &&
              stateAuth.context.id &&
              stateAuth.context.type !== "distributor" &&
              stateAuth.context.type !== "owner" && (
                <NavLink to="/shop">Витрина подарков</NavLink>
              )}

            <NavLink to="/feedback">Обратная связь</NavLink>
          </div>

          {!props.account &&
            stateAuth.matches("auth") &&
            stateAuth.context.type === "seller" && (
              <div className={clsx(styles.balance)}>
                {new Intl.NumberFormat("ru-RU").format(
                  stateAuth.context.balance,
                )}{" "}
                баллов
              </div>
            )}

          {props.account ? (
            ""
          ) : (
            <Link
              to={stateAuth.matches("auth") ? "/account" : "/auth"}
              className="btn btnPrimary"
            >
              <Icon
                className="icon"
                name="icon-userStroke"
                sizeW="24"
                sizeH="24"
              />

              {stateAuth.matches("auth") ? (
                <span>Личный кабинет</span>
              ) : (
                <span>Войти</span>
              )}
            </Link>
          )}
        </nav>
      </div>
    </header>
  );
}

export function Logo(props) {
  return (
    <a href="/">
      <span className="visuallyHidden">На главную</span>
      <svg 
        className={styles.logo}
        width="125"
        height="32"
        version="1.1" 
        id="Layer_1" 
        xmlns="http://www.w3.org/2000/svg" 
        x="0px" 
        y="0px"
        viewBox="0 0 93 30" xmlSpace="preserve"
      >
        <g>
          <defs>
            <rect id="SVGID_1_" width="93" height="30"/>
          </defs>
          <clipPath id="SVGID_00000099640305939963597650000004396647212929674678_">
            <use xlinkHref="#SVGID_1_" />
          </clipPath>
          <g>
            <path fill={props.dark ? "#fff" : "#000"} d="M68.5,6.3H93V0H60.1c-2.6,0-4.8,2.2-4.8,4.9v20.2c0,2.7,2.1,4.9,4.8,4.9H93v-6.3H68.5c-0.6,0-1-0.5-1-1.1V7.4
              C67.5,6.8,68,6.3,68.5,6.3z"/>
            <path fill={props.dark ? "#fff" : "#000"} d="M45.3,0c-2.6,0-4.8,2.2-4.8,4.9v21.5c0,1.3-0.7,2.2-1.2,2.7c-0.1,0.1-0.1,0-0.1-0.1v-0.4
              c0-2.7-2.1-4.9-4.8-4.9H16.5l19.6-16c1.2-1,1.9-2.7,1.4-4.4C37.1,1.3,35.3,0,33.4,0H0.8v6.3h20.4l-19.6,16c-1,0.8-1.6,2-1.6,3.3
              C0,28.1,1.9,30,4.2,30h43.7c2.6,0,4.8-2.2,4.8-4.9V3.6c0-2.1,1.7-3.1,1.7-3.1V0H45.3z"/>
          </g>
        </g>
      </svg>

      {/* <svg
        className={styles.logo}
        xmlns="http://www.w3.org/2000/svg"
        width="125"
        height="32"
        fill="none"
        viewBox="0 0 125 32"
        role="presentation"
      >
        <title>логотип SK ZIC</title>
        <path
          fill={props.dark ? "#fff" : "#000"}
          d="M66.75 24.239 79.682 7.673 75.54 2.354H53.007l4.263 5.502h12.12L52.336 29.72h23.711l4.284-5.481H66.75ZM92.045 2.354h-8.871V29.72h8.871V2.354ZM104.875 24.239V7.856h12.769l4.284-5.502h-21.905l-4.02 5.157v17.073l4.02 5.136h21.905l-4.284-5.481h-12.769Z"
        />
        <path
          fill={props.dark ? "#fff" : "#D02239"}
          fillRule="evenodd"
          d="M8.429 1.956c-4.429.84-7.306 4.019-7.306 8.07 0 2.037.707 3.802 2.075 5.18 1.156 1.164 2.098 1.77 5.104 3.277 3.655 1.833 4.428 2.52 4.428 3.932 0 .929-.416 1.546-1.367 2.028-1.967.997-4.89.684-7.85-.84-.63-.323-1.166-.526-1.193-.45-.026.076-.59 1.143-1.252 2.373C.123 27.28-.099 27.8.036 27.946c.314.34 2.727 1.29 4.255 1.674 5.21 1.312 10.025.45 12.77-2.289 1.627-1.623 2.373-3.4 2.373-5.653 0-2.815-1.262-5.01-3.87-6.73-.528-.348-2.128-1.22-3.555-1.939-1.427-.718-2.863-1.536-3.19-1.816-1.672-1.433-1.164-3.614.958-4.123.718-.172 1.09-.164 3.834.087 2.11.193 3.146-.56 4.042-2.94.416-1.104.47-1.034-1.195-1.55-2.656-.821-5.925-1.111-8.03-.711Zm14.754 14.219v13.862l1.045-.08c2.455-.186 4.066-1.307 4.888-3.401.294-.748.327-1.136.401-4.78l.082-3.964 4.372 5.947 4.372 5.948h7.933l-.425-.54c-.233-.298-2.768-3.516-5.632-7.15l-5.208-6.61 1.717-2.113c.945-1.163 3.335-4.093 5.312-6.512l3.596-4.397-3.766-.039c-3.46-.035-3.78-.018-3.949.216-1.683 2.329-8.31 10.997-8.406 10.997-.078 0-.132-2.313-.132-5.623V2.313h-6.2v13.862Z"
          clipRule="evenodd"
        />
      </svg> */}
    </a>
  );
}

function MobileMenu(props) {
  const [stateAuth] = useActor(AuthState);

  const dialog = useDialogState({
    animated: true,
    hideOnEsc: true,
    hideOnClickOutside: true,
  });

  return (
    <>
      <DialogDisclosure className={styles.burger} {...dialog}>
        <Icon name="icon-burger" sizeW="32" sizeH="32" />

        <span className="visuallyHidden">"Открыть меню"</span>
      </DialogDisclosure>
      <Dialog
        {...dialog}
        className={clsx("modal", styles.menuMobile, {
          [styles.dark]: props.dark,
        })}
        aria-label="Основная навигация"
      >
        <div className={clsx("modalHead", { dark: props.dark })}>
          <Logo dark={props.dark} />

          <button className="modalClose" onClick={dialog.hide}>
            <Icon name="icon-close" sizeW="32" sizeH="32" />
            <span className="visuallyHidden">Закрыть</span>
          </button>
        </div>
        <div className="modalBody">
          <div className={styles.menuMobileBody}>
            <div className={styles.links}>
              {!["distributor", "owner"].includes(stateAuth.context.type) &&
              stateAuth.context.id ? (
                <NavLink onClick={dialog.hide} to="/">
                  Главная
                </NavLink>
              ) : (
                <></>
              )}
              {stateAuth.context.id &&
                !["distributor", "owner"].includes(stateAuth.context.type) && (
                  <NavLink onClick={dialog.hide} to="/shop">
                    Витрина подарков
                  </NavLink>
                )}
              <NavLink onClick={dialog.hide} to="/feedback">
                Обратная связь
              </NavLink>
            </div>

            {!props.account && stateAuth.matches("auth") && (
              <>
                <hr />
                <div className={clsx(styles.balance)}>
                  У вас{" "}
                  {new Intl.NumberFormat("ru-RU").format(
                    stateAuth.context.balance,
                  )}{" "}
                  баллов
                </div>
              </>
            )}

            {props.account ? (
              ""
            ) : (
              <>
                <hr />
                <Link
                  to={stateAuth.matches("auth") ? "/account" : "/auth"}
                  className="btn btnPrimary"
                >
                  <Icon
                    className="icon"
                    name="icon-userStroke"
                    sizeW="24"
                    sizeH="24"
                  />

                  {stateAuth.matches("auth") ? (
                    <span>Личный кабинет</span>
                  ) : (
                    <span>Войти</span>
                  )}
                </Link>
              </>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
}

function UserMenuMobile() {
  const dialog = useDialogState({ animated: true });

  return (
    <>
      <DialogDisclosure {...dialog} className={styles.userMenu}>
        <Icon name="icon-user" sizeH="32" sizeW="32" />
      </DialogDisclosure>
      <Dialog
        {...dialog}
        className={clsx("modal", styles.menuMobile, styles.dark)}
        aria-label="Меню пользователя"
      >
        <div className={clsx("modalHead dark")}>
          <Logo dark />
          <button className="modalClose" onClick={dialog.hide}>
            <Icon name="icon-close" sizeW="32" sizeH="32" />
            <span className="visuallyHidden">Закрыть</span>
          </button>
        </div>
        <div className="modalBody">
          <AccountMenu dialog={dialog} />
        </div>
      </Dialog>
    </>
  );
}
