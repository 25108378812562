import React, {useCallback, useEffect, useState} from "react";
import {Popover, PopoverDisclosure, usePopoverState} from "reakit";
import {useMachine} from "@xstate/react";
import QrScanner from 'qr-scanner';
import Dropzone, {useDropzone} from 'react-dropzone';
import InputMask from "react-input-mask";
// import ReactTooltip from 'react-tooltip'
import { Tooltip } from 'react-tooltip'
import {Icon} from "../SVGsprite/svgsprite";
import styles from "./UploadReceip.module.scss";
import {uploadReceiptMachine} from "../../xstate/upload.machine";
import {Loader} from "../Loader/Loader";


export function UploadReceipt() {
    const [state, send] = useMachine(uploadReceiptMachine);

    /* Предпросмотр загружаемого изображения */

    const [receiptPreview, setReceiptPreview] = useState("");
    const [paths, setPaths] = useState([]);
    const [images, setImages] = useState([]);
    const [inputFields, setInputFields] = useState([]);
    const [qrData, setQrData] = useState([]);

    /* Обработка изменений файлового инпута */

    function handleFileChange(files) {
       const qrResult = {}

       setImages(files)
       setPaths(files.map(file => URL.createObjectURL(file)))

       send({
          type: "INPUT",
          data: {
              "image": files
          }
       })

       files.forEach((item) => {
           QrScanner.scanImage(item)
             .then((result) => {
               qrResult.value = result.split("&");
               const newDate = qrResult.value.map((value) => value.split("="));
               handleFormFromQr(newDate);
             })
             .catch((error) => console.log(error));
         });

         const handleFormFromQr = (data) => {
           data.forEach((value) => {
             if(value[0] === 'fn'){
               send({
                  type: "INPUT",
                  data: {
                      "fn": value[1]
                  }
              })
             }

             if(value[0] === 'fp'){
               send({
                  type: "INPUT",
                  data: {
                      "fp": value[1]
                  }
              })
             }

             if(value[0] === 'i'){
               send({
                  type: "INPUT",
                  data: {
                      "fd": value[1]
                  }
              })
             }

             if(value[0] === 's'){
               send({
                  type: "INPUT",
                  data: {
                      "sum": value[1]
                  }
              })
             }

             if(value[0] === 't'){
               const t = value[1]
               const chDate = value[1].split('T')[0].split('')
               const chTime = value[1].split('T')[1].split('')

               send({
                  type: "INPUT",
                  data: {
                      "time": `${chTime[0]+chTime[1]}:${chTime[2]}${chTime[3]}`

                  }
              })

              send({
                 type: "INPUT",
                 data: {
                     "date": `${chDate[6]+chDate[7]}.${chDate[4]}${chDate[5]}.${chDate[0]}${chDate[1]}${chDate[2]}${chDate[3]}`

                 }
               })
             }
           });
         };

         setInputFields(true)

       // QrScanner.scanImage(files[0])
       //       .then(result => {
       //         setQrData(result.split('&'))
       //
       //         let newDate;
       //         newDate = qrData.map(value =>{
       //           return value.split('=')
       //         })
       //
       //         console.log(newDate)
       //
       //         newDate.forEach(value => {
       //           if(value[0] === 'fn'){
       //             send({
       //                type: "INPUT",
       //                data: {
       //                    "fn": value[1]
       //                }
       //            })
       //           }
       //
       //           if(value[0] === 'fp'){
       //             send({
       //                type: "INPUT",
       //                data: {
       //                    "fp": value[1]
       //                }
       //            })
       //           }
       //
       //           if(value[0] === 'i'){
       //             send({
       //                type: "INPUT",
       //                data: {
       //                    "fd": value[1]
       //                }
       //            })
       //           }
       //
       //           if(value[0] === 's'){
       //             send({
       //                type: "INPUT",
       //                data: {
       //                    "sum": value[1]
       //                }
       //            })
       //           }
       //
       //           if(value[0] === 't'){
       //             let t = value[1]
       //             let chDate = value[1].split('T')[0].split('')
       //             let chTime = value[1].split('T')[1].split('')
       //
       //             send({
       //                type: "INPUT",
       //                data: {
       //                    "time": chTime[0]+chTime[1]+':'+chTime[2]+chTime[3]
       //
       //                }
       //            })
       //
       //            send({
       //               type: "INPUT",
       //               data: {
       //                   "date": chDate[6]+chDate[7]+'.'+chDate[4]+chDate[5]+'.'+chDate[0]+chDate[1]+chDate[2]+chDate[3]
       //
       //               }
       //             })
       //           }
       //         })
       //
       //         let reader = new FileReader();
       //         reader.onload = (e) => {
       //           let resultImages = []
       //
       //           resultImages.push({
       //             src: e.target.result,
       //             correct: true,
       //             data: files[0]
       //           })
       //         };
       //         reader.readAsDataURL(files[0])
       //       })
    }

    function deleteImage(index) {
      images.splice(index, 1)
      setPaths(images.map(file => URL.createObjectURL(file)))

      if (!images.filter(value => value.correct).length) {
        send({
           type: "INPUT",
           data: {
               "fd": '',
               "fp": '',
               "fn": '',
               "date": '',
               "time": '',
               "sum": '',
               "images": ''
           }
       })
      }

      if (images.length === 0){
        setInputFields(false)

        send({
           type: "INPUT",
           data: {
               "fd": '',
               "fp": '',
               "fn": '',
               "date": '',
               "time": '',
               "sum": '',
               "images": ''
           }
       })
      }
    }

    function submitForm(event) {
        event.preventDefault();
        send("SUBMIT_FIELDS");
    }

    useEffect(() => {
        document.title = "Зарегистрировать чек | Программа лояльности SK ZIC";
    }, []);

    return (
        <form
            className={styles.uploadingForm}
            onSubmit={(event) => submitForm(event)}
        >

            {
                state.matches({form: "sending_fields"}) &&
                <div className={styles.loading}><Loader/></div>
            }

            <h1>Зарегистрировать чек</h1>

            <div className={styles.infoWrapper}>
              <div>
                <p>
                  1. Добавьте фото или сфотографируйте чек с продукцией ZIC
                </p>

                <p>
                  2. Если чек длинный, сделайте несколько последовательных фото
                </p>

                <p>
                  3. Если на чеке есть QR-код - сделайте фото отдельным кадром
                </p>

                <p>
                  4. Если фото окажется плохого качества - чек принят не будет
                </p>
              </div>
            </div>

            {
                state.matches({form: "failure"}) && <>
                    {
                        state.context.errors.map((item, index)=><div className="errorMessage" key={index}>
                                {item}
                            </div>)
                    }
                </>
            }

            {
                state.matches({form: "success"}) &&
                <div className={styles.successMessage}>
                  <div className={styles.successContent}>
                      <h2>Загрузка чека <br /> прошла успешно</h2>

                      <Icon name="icon-success"
                            className={styles.iconSuccess}
                            sizeW="172"
                            sizeH="172" />
                  </div>
                </div>
            }

            <div className={styles.dropzoneWrapper}>
                  { paths.length < 1 &&
                    <Dropzone className={styles.scanner} onDrop={acceptedFiles =>
                                                                  handleFileChange(acceptedFiles)
                                                                 }>
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />

                            <div className={styles.scanner}>
                                  <p>
                                    Перетащите изображение сюда или сделайте фото чека
                                  </p>


                                  <div className="formRow">
                                      <a type="" className="btn btnPrimary btnForm">
                                          Добавить фото
                                      </a>
                                  </div>


                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  }

                  {
                    paths.length > 0
                    ? <>
                        { paths.map(
                            (item, index) => <span className={styles.imageWrapper}
                                                   key={index}>

                                                 <div onClick={() => {
                                                                deleteImage(index);
                                                               }}
                                                      className={styles.close}>
                                                   <Icon name="icon-close"
                                                         sizeW="24"
                                                         sizeH="24" />
                                                 </div>

                                                 <img src={item}
                                                      key={index}
                                                      className={styles.image}
                                                      alt="" />

                                               </span>

                          )
                        }
                      </>
                    : <></>
                  }
                </div>

            { inputFields === true &&

                <div className={styles.fieldsWrapper}>
                      <div className={styles.fields}>
                        <p className={styles.fieldsTitle}>
                          Перепроверьте или введите данные по чеку вручную
                        </p>

                        <div className={styles.inputsRow}>
                          <div className={styles.formInput}>
                              <label htmlFor="fp" className={styles.inputLabel}>
                                ФП
                                <span id="receipt_fp" className="my-anchor-element">
                                    <Icon 
                                        name="icon-question"
                                        sizeH="15" 
                                        sizeW="15"
                                    />
                                </span>
                                <Tooltip className={styles.tooltipNote} anchorSelect="#receipt_fp" place="top-end" type="dark" effect="float">
                                    <img src="/images/fp.jpg" alt="ФП"/>
                                </Tooltip>
                              </label>
                              <div className="inputWrapper">
                                  <input
                                      name="fp"
                                      id="fp"
                                      type="text"
                                      value={state.context.fp}
                                      inputMode="numeric"
                                      onChange={(event) => {
                                          send({
                                              type: "INPUT",
                                              data: {
                                                  "fp": event.target.value
                                              }
                                          })
                                      }}
                                  />
                              </div>

                              {
                                  state.context.fpError &&
                                  <div className="errorMessage" aria-live="polite">
                                      <span className="visuallyHidden">Ошибка в поле Фискальный признак документа:</span>
                                      {state.context.fpError}
                                  </div>
                              }
                          </div>

                          <div className={styles.formInput}>
                              <label htmlFor="fn" className={styles.inputLabel}>
                                ФН
                                <span 
                                    id="receipt_fn"
                                >
                                    <Icon 
                                        name="icon-question"
                                        sizeH="15" 
                                        sizeW="15"
                                    />
                                </span>
                                <Tooltip className={styles.tooltipNote} anchorSelect="#receipt_fn" place="top-end" type="dark" effect="float">
                                    <img src="/images/fn.jpg" alt="ФН"/>
                                </Tooltip>
                                {/* <ReactTooltip className={styles.tooltipNote} id="receipt_fn" place="top" type="dark" effect="float">
                                    <img src="/images/fn.jpg" alt="ФН"/>
                                </ReactTooltip> */}
                              </label>
                              <div className="inputWrapper">
                                  <InputMask
                                      name="fn"
                                      id="fn"
                                      type="text"
                                      mask="9999999999999999"
                                      placeholder="XXXXXXXXXXXXXXXX"
                                      value={state.context.fn}
                                      inputMode="numeric"
                                      onChange={(event) => {
                                          send({
                                              type: "INPUT",
                                              data: {
                                                  "fn": event.target.value
                                              }
                                          })
                                      }}
                                  />
                              </div>

                              {
                                  state.context.fnError &&
                                  <div className="errorMessage" aria-live="polite">
                                      <span className="visuallyHidden">Ошибка в поле Номер фискального накопителя:</span>
                                      {state.context.fnError}
                                  </div>
                              }
                          </div>
                        </div>

                        <div className={styles.inputsRow}>
                          <div className={styles.formInput}>
                              <label htmlFor="fd" className={styles.inputLabel}>
                                ФД
                                {/* data-tip
                                data-for="receipt_fd" */}
                                <span 
                                    id="receipt_fd"
                                >
                                    <Icon 
                                        name="icon-question"
                                        sizeH="15" 
                                        sizeW="15"
                                    />
                                </span>
                                <Tooltip className={styles.tooltipNote} anchorSelect="#receipt_fd" place="top-end" type="dark" effect="float">
                                    <img src="/images/fd.jpg" alt="ФД"/>
                                </Tooltip>
                                {/* <ReactTooltip className={styles.tooltipNote} id="receipt_fd" place="top" type="dark" effect="float">
                                    <img src="/images/fd.jpg" alt="ФД"/>
                                </ReactTooltip> */}
                              </label>
                              <div className="inputWrapper">
                                  <input
                                      name="fd"
                                      id="fd"
                                      value={state.context.fd}
                                      type="text"
                                      inputMode="numeric"
                                      onChange={(event) => {
                                          send({
                                              type: "INPUT",
                                              data: {
                                                  "fd": event.target.value
                                              }
                                          })
                                      }}
                                  />
                              </div>

                              {
                                  state.context.fdError &&
                                  <div className="errorMessage" aria-live="polite">
                                      <span className="visuallyHidden">Ошибка в поле Номер Фискального Документа:</span>
                                      {state.context.fdError}
                                  </div>
                              }
                          </div>

                          <div className={styles.formInput}>
                              <label htmlFor="date" className={styles.inputLabel}>
                                  Дата покупки
                                  <span 
                                    id="receipt_date"
                                >
                                    <Icon 
                                        name="icon-question"
                                        sizeH="15" 
                                        sizeW="15"
                                    />
                                </span>
                                <Tooltip className={styles.tooltipNote} anchorSelect="#receipt_date" place="top-end" type="dark" effect="float">
                                    <img src="/images/date.jpg" alt="Дата"/>
                                </Tooltip>
                                {/* <ReactTooltip className={styles.tooltipNote} id="receipt_date" place="top" type="dark" effect="float">
                                    <img src="/images/date.jpg" alt="Дата"/>
                                </ReactTooltip> */}
                              </label>
                              <div className="inputWrapper">
                                  <InputMask
                                      id="date"
                                      name="date"
                                      type="text"
                                      mask="99.99.9999"
                                      placeholder="ДД.ММ.ГГГГ"
                                      value={state.context.date}
                                      onChange={(event) => {
                                          send({
                                              type: "INPUT",
                                              data: {
                                                  "date": event.target.value
                                              }
                                          })
                                      }}
                                  />
                              </div>

                              {
                                  state.context.dateError &&
                                 <div className="errorMessage" aria-live="polite">
                                     <span className="visuallyHidden">Ошибка в поле дата покупки:</span>
                                     {state.context.dateError}
                                 </div>
                              }
                          </div>
                        </div>

                        <div className={styles.inputsRow}>
                          <div className={styles.formInput}>
                              <label htmlFor="sum" className={styles.inputLabel}>
                                Сумма чека
                                <span 
                                    id="receipt_total"
                                >
                                    <Icon 
                                        name="icon-question"
                                        sizeH="15" 
                                        sizeW="15"
                                    />
                                </span>
                                <Tooltip className={styles.tooltipNote} anchorSelect="#receipt_total" place="top-end" type="dark" effect="float">
                                    <img src="/images/total.jpg" alt="Сумма"/>
                                </Tooltip>
                                {/* <ReactTooltip className={styles.tooltipNote} id="receipt_total" place="top" type="dark" effect="float">
                                    <img src="/images/total.jpg" alt="Сумма"/>
                                </ReactTooltip> */}
                              </label>
                              <div className="inputWrapper">
                                  <input
                                      name="sum"
                                      id="sum"
                                      type="text"
                                      placeholder="XXX.XX"
                                      value={state.context.sum}
                                      onChange={(event) => {
                                          send({
                                              type: "INPUT",
                                              data: {
                                                  "sum": event.target.value
                                              }
                                          })
                                      }}
                                  />
                              </div>

                              {
                                  state.context.sumError &&
                                  <div className="errorMessage" aria-live="polite">
                                      <span className="visuallyHidden">Ошибка в поле сумма чека:</span>
                                      {state.context.sumError}
                                  </div>
                              }
                          </div>

                          <div className={styles.formInput}>
                              <label htmlFor="time" className={styles.inputLabel}>
                                  Время покупки
                                  <span 
                                    id="receipt_time"
                                >
                                    <Icon 
                                        name="icon-question"
                                        sizeH="15" 
                                        sizeW="15"
                                    />
                                </span>
                                <Tooltip className={styles.tooltipNote} anchorSelect="#receipt_time" place="top-end" type="dark" effect="float">
                                    <img src="/images/time.jpg" alt="Время"/>
                                </Tooltip>
                                {/* <ReactTooltip className={styles.tooltipNote} id="receipt_time" place="top" type="dark" effect="float">
                                    <img src="/images/time.jpg" alt="Время"/>
                                </ReactTooltip> */}
                              </label>
                              <div className="inputWrapper">
                                  <InputMask
                                      name="time"
                                      id="time"
                                      type="text"
                                      mask="99:99"
                                      placeholder="00:00"
                                      value={state.context.time}
                                      onChange={(event) => {
                                          send({
                                              type: "INPUT",
                                              data: {
                                                  "time": event.target.value
                                              }
                                          })
                                      }}
                                  />
                              </div>
                              {
                                  state.context.timeError &&
                                  <div className="errorMessage" aria-live="polite">
                                      <span className="visuallyHidden">Ошибка в поле время покупки:</span>
                                      {state.context.timeError}
                                  </div>
                              }
                          </div>
                        </div>
                      </div>
                    </div>
            }

            { inputFields === true &&
              <div className="formRow">
                    <button type="submit"
                            disabled={
                                       !state.context.fd || !state.context.fn ||
                                       !state.context.fp || !state.context.date ||
                                       !state.context.time || !state.context.sum ||
                                       !state.context.image
                                     }
                            className="btn btnPrimary btnForm">
                        Отправить чек
                    </button>
                </div>
            }
        </form>
    )
}
