import styles from "./AccountMenu.module.scss";
import { Link, NavLink } from "react-router-dom";
import { Icon } from "../SVGsprite/svgsprite";
import { useActor } from "@xstate/react";
import { AuthState } from "../../xstate/auth.machine";
import { useEffect, useCallback, useState } from "react";
import apiMethods from "../../api/apiMethods";

export function AccountMenu(props) {
  let [stateAuth, sendAuth] = useActor(AuthState);
  
  const [rules, setRules] = useState();

  const fetchRules = useCallback(async () => {
    try {
      const {data} = await apiMethods.docs.getDocs();
      const rules = data?.data?.find(item => item.type === 'rules')
      setRules(rules || null)
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchRules();
  },[fetchRules]);


  const [position, setPosition] = useState()

  useEffect(() => {
    if (stateAuth.context.type === 'seller') {
      setPosition('Продавец')
    } else if (stateAuth.context.type === 'owner') {
      setPosition('Партнер')
    } else {
      setPosition('Дистрибьютор')
    }

  }, [stateAuth.context.type])

  return (
    <section className={styles.accMenu}>
      {stateAuth.context.errorMsg && stateAuth.context.errorMsg.length > 0 && (
        <>
          {Array.isArray(stateAuth.context.errorMsg) ? stateAuth.context.errorMsg?.map((item, index) => {
            return (
              <div className={"errorMessage"} key={index}>
                item
              </div>
            );
          }) : <></>}
        </>
      )}
      <div className={styles.profile}>
        <div className={styles.userInfo}>
          <div className={styles.userpic}>
            <img
              src={
                stateAuth.context?.avatar
                  ? stateAuth.context?.avatar
                  : "/images/user-ph.png"
              }
              alt=""
            />
          </div>
          <div className={styles.userName}>{stateAuth.context.name} { stateAuth.context.surname }</div>
          <div className="">{ position }</div>
          <Link onClick={() => props.dialog?.hide()} to={"/account"}>
            Редактировать профиль
          </Link>

          {stateAuth.context.type === "seller" ? (
            <NavLink
              className={styles.balance}
              to={"transactions"}
              onClick={() => props.dialog?.hide()}
            >
              Счет:{" "}
              {new Intl.NumberFormat("ru-RU").format(stateAuth.context.balance)}{" "}
              баллов
            </NavLink>
          ) : (
            ""
          )}
        </div>

        <hr />
        <nav
          className={styles.navigation}
          aria-label={"Навигация по личному кабинету"}
        >
          {stateAuth.context.type === "seller" && (
            <NavLink
              className={"btn btnSecondary btnIcon"}
              to={"receipts"}
              onClick={() => props.dialog?.hide()}
            >
              <Icon name={"icon-list"} sizeW="16" sizeH="16" />
              Мои чеки
            </NavLink>
          )}

          {stateAuth.context.type === "seller" && (
            <NavLink
              className={"btn btnSecondary btnIcon"}
              to={"sales"}
              onClick={() => props.dialog?.hide()}
            >
              <Icon name={"icon-chart"} sizeW="16" sizeH="16" />
              Мои продажи
            </NavLink>
          )}
          {stateAuth.context.type !== "seller" && (
            <NavLink
              className={"btn btnSecondary btnIcon"}
              to={"statistics"}
              onClick={() => props.dialog?.hide()}
            >
              <Icon name={"icon-statistics"} sizeW="16" sizeH="16" />
              Статистика
            </NavLink>
          )}

          {stateAuth.context.type === "seller" && (
            <NavLink
              className={"btn btnSecondary btnIcon"}
              to={"gifts"}
              onClick={() => props.dialog?.hide()}
            >
              <Icon name={"icon-starStroke"} sizeW="16" sizeH="16" />
              Мои подарки
            </NavLink>
          )}

          {stateAuth.context.type === "seller" && (
            <NavLink
              className={"btn btnSecondary btnIcon"}
              to={"transactions"}
              onClick={() => props.dialog?.hide()}
            >
              <Icon name={"icon-chart"} sizeW="16" sizeH="16" />
              Мои баллы
            </NavLink>
          )}

          {stateAuth.context.type === "owner" && (
            <NavLink
              className={"btn btnSecondary btnIcon"}
              to={"sale-point"}
              onClick={() => props.dialog?.hide()}
            >
              <Icon name={"icon-point"} sizeW="16" sizeH="16" />
              Торговые точки
            </NavLink>
          )}

          {stateAuth.context.type === "owner" && (
            <NavLink
              className={"btn btnSecondary btnIcon"}
              to={"sellers"}
              onClick={() => props.dialog?.hide()}
            >
              <Icon name={"icon-userStroke"} sizeW="16" sizeH="16" />
              Продавцы
            </NavLink>
          )}

          {stateAuth.context.type === "distributor" && (
            <NavLink
              className={"btn btnSecondary btnIcon"}
              to={"sale-points"}
              onClick={() => props.dialog?.hide()}
            >
              <Icon name={"icon-point"} sizeW="16" sizeH="16" />
              Торговые точки
            </NavLink>
          )}

          {stateAuth.context.type === "distributor" && (
            <NavLink
              className={"btn btnSecondary btnIcon"}
              to={"owners"}
              onClick={() => props.dialog?.hide()}
            >
              <Icon name={"icon-userStroke"} sizeW="16" sizeH="16" />
              Партнеры
            </NavLink>
          )}
        </nav>
      </div>

      <div className={styles.logout}>
        <a className={styles.vk} href="https://vk.com/zicrussia" target="__blank">ZIC Russia | VK</a>
        <a className={styles.vk} href={Boolean(rules?.url) ? rules.url : "#"} target="__blank">Правила программы</a>
        <button
          className={"btn btnSecondary btnIcon"}
          onClick={() => sendAuth({ type: "logout" })}
        >
          <Icon name={"icon-logout"} sizeW="16" sizeH="16" />
          Выйти из&nbsp;профиля
        </button>
      </div>
    </section>
  );
}
