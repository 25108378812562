import { Link } from "react-router-dom";
import {useMachine, useActor} from "@xstate/react"
import styles from "./SalePointRegister.module.scss";
import { columsSalePointsNew, columsSalePointsAll } from '@/js/constants'
// import { Table } from '@/js/modules/Table/Table'
import { salePointsDist } from '@/js/xstate/salePointsDist.machine'
import { salePointsDistAll } from "@/js/xstate/salePointsDistAll";
// import {Pagination} from "@/js/modules/Pagination/Pagination";
import { TableSalePointsNew } from './TableSalePointsNew'
import { TableSalePointsAll } from './TableSalePointsAll'
import { AuthState } from "@/js/xstate/auth.machine";


const statuses = {
    in_process: {
        label: 'В обработке',
        color: 'orange'
    }, 
    active: {
        label: 'Активная',
        color: 'lightgreen'
    },
    inactive: {
        label: 'Неактивная',
        color: 'grey'
    }, 
    reject: {
        label: 'Отклоненная',
        color: 'red'
    }
}

export function SalePointRegister() {
    const [stateSalePoints, sendSalePoints] = useMachine(salePointsDist)
    const [stateSalePointsAll, sendSalePointsDistAll] = useMachine(salePointsDistAll)
    const [authState] = useActor(AuthState);

    const {meta} = stateSalePoints.context
    const metaAll = stateSalePointsAll.context.meta


  return (
    <section className={styles.container}>
      <div className="wrapper">
        <div className={styles.header}>
          <h2>Торговые точки</h2>
          {/* <Link to={"/account/sale-point-edit/1"} className={"btn btnPrimary btnIcon"}> */}
          <Link
            to="/account/sale-point-register"
            className="btn btnPrimary btnIcon"
          >
            Зарегистрировать торговую точку
          </Link>
        </div>
        {['owner'].includes(authState.context.type) ? (
          <div className={styles.tableWrapper}>
            <h3 className={styles.tableHeader}>
              Новые заявки на регистрацию Торговых Точек
            </h3>
            <TableSalePointsNew 
                columns={columsSalePointsNew}
                statePoints={stateSalePoints}
                meta={meta}
                onSend={sendSalePoints}
            />
          </div>
        ) : <></>}
        <h3 className={styles.tableHeader}>Все Торговые Точки</h3>
        <TableSalePointsAll 
            columns={columsSalePointsAll}
            statePoints={stateSalePointsAll}
            meta={metaAll}
            onSend={sendSalePointsDistAll}
        />
      </div>
    </section>
  );
}
