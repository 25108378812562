import { useActor } from "@xstate/react";
import { Navigate } from "react-router-dom";
import { AuthState } from "@/js/xstate/auth.machine";

export function ProtectedRoute ({ roles, redirectUrl, children }) {
  const [authState] = useActor(AuthState);

  if (roles.includes(authState.context.type)) {
    return children 
  } 
  if (!localStorage.getItem('sessionID')) {
    return <Navigate to="/auth" replace />
  }

  return <Navigate to={redirectUrl} replace />
}