import styles from "./About.module.scss"
import {Icon} from "../SVGsprite/svgsprite";

export function About() {
    return (<section className={styles.about}>
        <div className="wrapper">
            <div className={styles.aboutContainer}>
                <div className={styles.textSection}>
                    <h2 className={"line"}>
                        Привет, ZIC ПРОФИ!
                    </h2>
                    <p>Программа  «ZIC ПРОФИ» разработана специалистами «СК Энмув Рус» для консультантов розничных сетей и станций технического обслуживания.</p>

                    <p>Здесь Вы сможете конвертировать Ваш опыт <br /> и знания в баллы и призы, а в дальнейшем профессионально развиваться, проходя тренинги, квизы и сертификацию на платформе.</p>

                    <p>Продавайте товары, участвующие в программе, сканируйте чеки, а накопленные баллы обменивайте на все что  Вам понравится в нашем каталоге желаний! </p>
                </div>
                <div className={styles.imageSection}>
                    <img src="/images/zic-bottle.png"
                         alt="Бутылка моторного масла ZIC Top в окружении золотистых монет"/>
                </div>
            </div>
            <div className={styles.features}>
                <article className={styles.featureCard}>
                    <div className={styles.featureIcon}>
                        <Icon name={"icon-cart"} sizeH="33" sizeW="33" />
                    </div>
                    <h3>
                        Продавайте <br/><span>товары ZIC</span>
                    </h3>
                </article>
                <article className={styles.featureCard}>
                    <div className={styles.featureIcon}>
                        <Icon name={"icon-scanner"} sizeH="33" sizeW="33" />
                    </div>
                    <h3>
                        Загружайте <br/><span>чеки</span>
                    </h3>

                </article>
                <article className={styles.featureCard}>
                    <div className={styles.featureIcon}>
                        <Icon name={"icon-coins"} sizeH="33" sizeW="33" />
                    </div>
                    <h3>
                        Копите <br/><span>баллы</span>
                    </h3>

                </article>
                <article className={styles.featureCard}>
                    <div className={styles.featureIcon}>
                        <Icon name={"icon-star"} sizeH="33" sizeW="33" />
                    </div>
                    <h3>
                        Обменивайте <br/><span>баллы на&nbsp;ценные призы</span>
                    </h3>

                </article>

            </div>
        </div>
    </section>)
}