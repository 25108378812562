import { FormTemplate } from "../../FormTemplate/FormTemplate";

export function SalePointRegisterForm() {

    return (
            <FormTemplate title='Регистрация Торговой Точки' isLoading={false}>
                {
                    // stateFeedback.matches("success")
                    //     ? <div className={styles.successMessage}>
                    //         <p className={"h2"}>Ваше обращение отправлено</p>
                    //         <button
                    //             type="button"
                    //             className={"btn btnPrimary"}
                    //             onClick={() => sendFeedback({type: "ONE_MORE"})}
                    //         >
                    //             Отправить еще одно
                    //         </button>
                    //     </div>
                        <form onSubmit={
                                (event) => {
                                    event.preventDefault();
                                }
                            }
                        >
                            <div className="formRow">
                                <label htmlFor="name">
                                    Название
                                </label>
                                <input
                                    id="name"
                                    type="text"
                                    name="name"
                                    onChange={(event) => {
                                    }}
                                />
                                {/* {
                                    stateFeedback.context.errorMessage.map(item=> {
                                        return item.emailError !== "" && <div className={"errorMessage"} key={"errEmail"}>
                                            {item.emailError}
                                        </div>
                                    })
                                } */}
                            </div>
                            <div className="formRow">
                                <label htmlFor="fullName">
                                    Полное/Юр название
                                </label>
                                <input
                                    id="fullName"
                                    type="text"
                                    name="fullName"
                                    onChange={(event) => {
                                    }}
                                />
                                {/* {
                                    stateFeedback.context.errorMessage.map(item=> {
                                        return item.emailError !== "" && <div className={"errorMessage"} key={"errEmail"}>
                                            {item.emailError}
                                        </div>
                                    })
                                } */}
                            </div>
                            <div className="formRow">
                                <label htmlFor="region">
                                    Регион
                                </label>
                                <select
                                    className="select"
                                    id="region"
                                    type="text"
                                    name="region"
                                    onChange={(event) => {
                                    }}
                                    
                                >
                                    <option value="1">ЮФО</option>
                                    <option value="2">ЦФО</option>
                                    <option value="3">САО</option>
                                </select>
                                {/* {
                                    stateFeedback.context.errorMessage.map(item=> {
                                        return item.emailError !== "" && <div className={"errorMessage"} key={"errEmail"}>
                                            {item.emailError}
                                        </div>
                                    })
                                } */}
                            </div>
                            <div className="formRow">
                                <label htmlFor="address">
                                    Адрес
                                </label>
                                <input
                                    id="address"
                                    type="text"
                                    name="address"
                                    onChange={(event) => {
                                    }}
                                />
                                {/* {
                                    stateFeedback.context.errorMessage.map(item=> {
                                        return item.emailError !== "" && <div className={"errorMessage"} key={"errEmail"}>
                                            {item.emailError}
                                        </div>
                                    })
                                } */}
                            </div>
                            <div className="formRow">
                                <label htmlFor="owner">
                                    Партнер
                                </label>
                                <select
                                    className="select"
                                    id="owner"
                                    type="text"
                                    name="owner"
                                    onChange={(event) => {
                                    }}
                                    
                                >
                                    <option value="1">Петров Иван</option>
                                    <option value="2">Иван Петров</option>
                                    <option value="3">Иванов Петр</option>
                                </select>
                                {/* {
                                    stateFeedback.context.errorMessage.map(item=> {
                                        return item.emailError !== "" && <div className={"errorMessage"} key={"errEmail"}>
                                            {item.emailError}
                                        </div>
                                    })
                                } */}
                            </div>
                            <div className="formRow">
                                <button type={"submit"} className={"btn btnPrimary"}>
                                    Зарегистрировать
                                </button>
                            </div>
                        </form>
                }

            </FormTemplate>
    )
}