export const periodsList = [
  {
    value: "week",
    label: "Неделя (по дням)",
  },
  {
    value: "month",
    label: "Месяц",
  },
  {
    value: "six_month",
    label: "6 месяцев",
  },
];

export const typeList = [
  {
    value: "price",
    label: "Cумма продаж",
  },
  {
    value: "liters",
    label: "Kоличество (литры)",
  },
];

export const ownerDistColumns = [
  {
    title: "Фамилия и Имя Партнера",
    field: "name",
  },
  {
    title: "E-mail Партнера",
    field: "email",
  },
  {
    title: "Торговые точки",
    field: "sale_points",
  },
];

export const ownerSalePointsColumns = [
  {
    title: "Название торговой точки",
    field: "name",
  },
  {
    title: "Адрес",
    field: "address",
  },
  {
    title: "Статус",
    field: "status",
  },
];

export const sellerSalesColumns = [
  {
    title: "Дата и время продажи",
    field: "date",
  },
  {
    title: "Товар",
    field: "good",
  },
  {
    title: "Кол-во ед.",
    field: "quantity",
  },
  {
    title: "Кол-во литров",
    field: "liters",
  },
  {
    title: "Сумма продаж",
    field: "sum",
  },
  {
    title: "Баллов получено",
    field: "points",
  },
];

export const columsSalePointsNew = [
  {
    title: "Название ТТ",
    field: "name",
  },
  {
    title: "Фамилия и Имя Партнера",
    field: "owner",
  },
  {
    title: "Полное/Юр название",
    field: "entity_name",
  },
  {
    title: "Регион",
    field: "region",
  },
  {
    title: "Адрес",
    field: "address",
  },
  {
    title: "",
    field: "actions",
  },
];

export const columsSalePointsAll = [
  {
    title: "Название ТТ",
    field: "name",
  },
  {
    title: "Фамилия и Имя Партнера",
    field: "owner",
  },
  {
    title: "Полное/Юр название",
    field: "entity_name",
  },
  {
    title: "Регион",
    field: "region",
  },
  {
    title: "Адрес",
    field: "address",
  },
  {
    title: "Статус",
    field: "status",
  },
  {
    title: "",
    field: "edit",
  },
];

export const validationErrorMessages = {
  REQUIRED: "Поле не может быть пустым",
  INVALID_INPUT: "Поле заполнено некорректно",
  MAX_LENGTH: "Максимальное количество символов: ",
  MIN_LENGTH: "Минимльное количество символов: ",
};

export const patterns = {
  EMAIL: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
  PHONE: /^[0-9]{10}/g,
};
